import { postNotification } from '..'
import {
  createWebHook,
  deleteWebHook,
  fetchWebHook,
  fetchWebHookEvents,
  fetchWebHookProducers,
  fetchWebHookProviders,
  fetchWebHooks,
  updateWebHook,
} from '../../../http'
import {
  SET_WEB_HOOK,
  SET_WEB_HOOKS,
  SET_WEB_HOOKS_EVENTS,
  SET_WEB_HOOKS_PRODUCERS,
  SET_WEB_HOOKS_PROVIDERS,
} from '../../actionsTypes'

export const generateAllOrItems = (val) => (val[0] === '*' ? '*' : val)

const modifyWh = (webHook) => {
  const newWh = JSON.parse(JSON.stringify(webHook))
  newWh.eventSlugs = generateAllOrItems(webHook.eventSlugs)
  newWh.providerSlugs = generateAllOrItems(webHook.providerSlugs)
  newWh.scopes = generateAllOrItems(webHook.scopes)
  return newWh
}

// TODO: Remove or adapt after NEBINT-4182 is done
export const computeWhStatus = (webHookData) => {
  const webHookEnabled = webHookData.enabled ? 'enabled' : 'failed'
  // Hack to be able to develop draft status on the front-end side before it's available on the back-end
  const whStatus =
    webHookData.id === '6440afcb-932b-15a2-8193-70f2897a001a'
      ? 'draft'
      : webHookEnabled

  return { ...webHookData, status: whStatus }
}

export const getWebHook = (webHookId) => async (dispatch) => {
  try {
    const upstreamWebHook = await fetchWebHook(webHookId)
    // TODO: Remove or adapt after NEBINT-4182 is done
    const webHook = computeWhStatus(upstreamWebHook)
    dispatch(getWebHookProviders(webHook.producerSlug))
    dispatch(getWebHookEvents(webHook.producerSlug))
    dispatch(setWebHook(modifyWh(webHook)))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while fetching webHook: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const getWebHooks = (params) => async (dispatch) => {
  try {
    const webHooks = await fetchWebHooks(params)
    webHooks.content = webHooks?.content?.map(computeWhStatus) ?? []
    dispatch(setWebHooks(webHooks))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while fetching webHooks: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const addNewWebHook = (webHookObject, cb) => async (dispatch) => {
  try {
    await createWebHook(webHookObject)
    dispatch(
      postNotification({
        message: `New web-hook was successfully created!`,
      }),
    )
    cb?.()
    dispatch(getWebHooks())
  } catch (err) {
    cb?.()
    dispatch(
      postNotification({
        message: `Error occured while creating web-hook: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const removeWebHook = (webHookId, cb) => async (dispatch) => {
  try {
    await deleteWebHook(webHookId)
    dispatch(
      postNotification({
        message: `Web-hook was successfully removed!`,
      }),
    )
    cb?.()
    dispatch(getWebHooks())
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while removing web-hook: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const changeWebHook = (webHookObject, cb) => async (dispatch) => {
  try {
    await updateWebHook(webHookObject)
    dispatch(
      postNotification({
        message: `Web-hook was successfully updated!`,
      }),
    )
    cb?.()
    dispatch(getWebHooks())
  } catch (err) {
    cb?.()
    dispatch(
      postNotification({
        message: `Error occured while updating web-hook: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const getWebHookProducers = () => async (dispatch) => {
  try {
    const producers = await fetchWebHookProducers()
    dispatch(setWebHookProducers(producers))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while fetching producers: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const getWebHookEvents = (producerSlug) => async (dispatch) => {
  try {
    const events = producerSlug
      ? await fetchWebHookEvents(producerSlug)
      : { content: [] }
    dispatch(setWebHookEvents(events.content))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while fetching events: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const getWebHookProviders = (producerSlug) => async (dispatch) => {
  try {
    const providers = producerSlug
      ? await fetchWebHookProviders(producerSlug)
      : { content: [] }
    dispatch(setWebHookProviders(providers.content))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while fetching providers: ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const setWebHook = (webHook) => {
  return {
    type: SET_WEB_HOOK,
    payload: webHook,
  }
}

export const setWebHooks = (webHooks) => {
  return {
    type: SET_WEB_HOOKS,
    payload: webHooks,
  }
}

export const setWebHookProducers = (producers) => {
  return {
    type: SET_WEB_HOOKS_PRODUCERS,
    payload: producers,
  }
}

export const setWebHookProviders = (events) => {
  return {
    type: SET_WEB_HOOKS_PROVIDERS,
    payload: events,
  }
}

export const setWebHookEvents = (providers) => {
  return {
    type: SET_WEB_HOOKS_EVENTS,
    payload: providers,
  }
}
