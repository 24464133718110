import { TDMSClient } from '@tdms/tdms-client'
import { BASE_URL } from '../constants'
import { actionHandlers } from '../customFetch'

class APIClient {
  constructor() {
    this.apiUrl = BASE_URL
    this.client = null
    this.controller = new AbortController()
    this.controller.signal.onabort = (event) => {
      actionHandlers[event.currentTarget.reason]?.()
    }
  }

  initialize(csrfTokenData) {
    const options = {
      url: this.apiUrl,
      headers: this.generateHeaders(csrfTokenData),
      abortController: this.controller,
      axiosTimeout: 24000,
    }
    this.client = new TDMSClient(options)
  }

  generateHeaders(csrfTokenResponse) {
    return {
      [csrfTokenResponse.headerName]: csrfTokenResponse.token,
    }
  }
}

const client = new APIClient()
export const tdmsClient = client
